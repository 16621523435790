import React, { Fragment, useState } from "react";
/** Importing functional components */
import { useStaticQuery, graphql } from "gatsby";
import DefaultPage from "../layout/DefaultPage";

import {
  BlogLayout,
  ContactContainer,
  PostRow,
  StaticImage,
  H1,
  H3,
  Paragraph,
  RootBorder,
  TextField,
  TextArea,
  ContactButton
} from "../components/StyledComponent";

import { Col } from "react-flexbox-grid";

const ContactMe = () => {
  const contactData = useStaticQuery(
    graphql`
      query {
        contactYaml {
          email
          phone
          featuredImage
        }
      }
    `
  );

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  return (
    <Fragment>
      <DefaultPage pageTitle="Contacto">
        <BlogLayout>
          <RootBorder />
          <StaticImage loading="lazy" style={{height: "40vh"}} maxWidth="1920" src={contactData.contactYaml.featuredImage}></StaticImage>
          <ContactContainer>
            <PostRow>
              <Col xs={12} style={{marginBottom: ".5rem"}}>
                <H1 color="var(--secondary)">Contacto</H1>
              </Col>
              <Col xs={12}>
                <Paragraph>
                  Correo Electrónico:{" "}
                  <span style={{ color: "var(--secondary)" }}>
                    {contactData.contactYaml.email}
                  </span>
                </Paragraph>
              </Col>
              <Col xs={12} style={{marginBottom: ".5rem"}}>
                <Paragraph>
                  Teléfono:{" "}
                  <span style={{ color: "var(--secondary)" }}>
                    {contactData.contactYaml.phone}
                  </span>
                </Paragraph>
              </Col>
              <Col xs={12} style={{marginBottom: ".75rem"}}>
                <H3 color="var(--primary)">Contáctame</H3>
              </Col>
              <Col xs={12}>
                <form>
                  <TextField type="text"
                    onChange={e => setName(e.target.value)}
                    value={name}
                    name="name"
                    placeholder="Nombre"></TextField>
                  <TextField
                    type="text"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    name="subject"
                    placeholder="Asunto"></TextField>
                  <TextArea
                    value={body}
                    onChange={e => {
                      setBody(e.target.value);
                    }}
                    name="body"
                    placeholder="Type your message here..."
                  />
                  <ContactButton
                    href={`mailto:${contactData.contactYaml.email}?subject=${subject} - ${name}&body=${body}`}
                  >Enviar Correo</ContactButton>
                </form>
              </Col>
            </PostRow>
          </ContactContainer>
        </BlogLayout>
      </DefaultPage>
    </Fragment>
  );
};

export default ContactMe;
